//@ts-nocheck
/**
 * Generated by orval v6.12.0 🍺
 * Do not edit manually.
 * Datek eCommerce API
 * Datek eCommerce API
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { customInstance } from '../../httpClient'
import type { ErrorType } from '../../httpClient'
import type {
  CreateSpecialOfferDefault,
  FindSpecialOffers200,
  FindSpecialOffersDefault,
  FindSpecialOffersParams,
  GetOneRandomSpecialOfferDefault,
  GetSpecialOfferDefault,
  SpecialOffer,
  UpdateSpecialOfferBody,
  UpdateSpecialOfferDefault,
} from '.././model'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never

export const findSpecialOffers = (
  params?: FindSpecialOffersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<FindSpecialOffers200>(
    { url: `/api/special-offers`, method: 'get', params, signal },
    options,
  )
}

export const getFindSpecialOffersQueryKey = (params?: FindSpecialOffersParams) => [
  `/api/special-offers`,
  ...(params ? [params] : []),
]

export type FindSpecialOffersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof findSpecialOffers>>
>
export type FindSpecialOffersInfiniteQueryError = ErrorType<FindSpecialOffersDefault>

export const useFindSpecialOffersInfinite = <
  TData = Awaited<ReturnType<typeof findSpecialOffers>>,
  TError = ErrorType<FindSpecialOffersDefault>,
>(
  params?: FindSpecialOffersParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof findSpecialOffers>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFindSpecialOffersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findSpecialOffers>>> = ({
    signal,
    pageParam,
  }) => findSpecialOffers({ _cursor: pageParam, ...params }, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof findSpecialOffers>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type FindSpecialOffersQueryResult = NonNullable<
  Awaited<ReturnType<typeof findSpecialOffers>>
>
export type FindSpecialOffersQueryError = ErrorType<FindSpecialOffersDefault>

export const useFindSpecialOffers = <
  TData = Awaited<ReturnType<typeof findSpecialOffers>>,
  TError = ErrorType<FindSpecialOffersDefault>,
>(
  params?: FindSpecialOffersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof findSpecialOffers>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFindSpecialOffersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findSpecialOffers>>> = ({ signal }) =>
    findSpecialOffers(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof findSpecialOffers>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const createSpecialOffer = (
  specialOffer: SpecialOffer,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SpecialOffer>(
    {
      url: `/api/special-offers`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: specialOffer,
    },
    options,
  )
}

export type CreateSpecialOfferMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSpecialOffer>>
>
export type CreateSpecialOfferMutationBody = SpecialOffer
export type CreateSpecialOfferMutationError = ErrorType<CreateSpecialOfferDefault>

export const useCreateSpecialOffer = <
  TError = ErrorType<CreateSpecialOfferDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSpecialOffer>>,
    TError,
    { data: SpecialOffer },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSpecialOffer>>,
    { data: SpecialOffer }
  > = (props) => {
    const { data } = props ?? {}

    return createSpecialOffer(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof createSpecialOffer>>,
    TError,
    { data: SpecialOffer },
    TContext
  >(mutationFn, mutationOptions)
}
export const getOneRandomSpecialOffer = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SpecialOffer>(
    { url: `/api/special-offers/random`, method: 'get', signal },
    options,
  )
}

export const getGetOneRandomSpecialOfferQueryKey = () => [`/api/special-offers/random`]

export type GetOneRandomSpecialOfferInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOneRandomSpecialOffer>>
>
export type GetOneRandomSpecialOfferInfiniteQueryError = ErrorType<GetOneRandomSpecialOfferDefault>

export const useGetOneRandomSpecialOfferInfinite = <
  TData = Awaited<ReturnType<typeof getOneRandomSpecialOffer>>,
  TError = ErrorType<GetOneRandomSpecialOfferDefault>,
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getOneRandomSpecialOffer>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOneRandomSpecialOfferQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneRandomSpecialOffer>>> = ({
    signal,
  }) => getOneRandomSpecialOffer(requestOptions, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getOneRandomSpecialOffer>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export type GetOneRandomSpecialOfferQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOneRandomSpecialOffer>>
>
export type GetOneRandomSpecialOfferQueryError = ErrorType<GetOneRandomSpecialOfferDefault>

export const useGetOneRandomSpecialOffer = <
  TData = Awaited<ReturnType<typeof getOneRandomSpecialOffer>>,
  TError = ErrorType<GetOneRandomSpecialOfferDefault>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getOneRandomSpecialOffer>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOneRandomSpecialOfferQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneRandomSpecialOffer>>> = ({
    signal,
  }) => getOneRandomSpecialOffer(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getOneRandomSpecialOffer>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getSpecialOffer = (
  specialOfferId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SpecialOffer>(
    { url: `/api/special-offers/${specialOfferId}`, method: 'get', signal },
    options,
  )
}

export const getGetSpecialOfferQueryKey = (specialOfferId: string) => [
  `/api/special-offers/${specialOfferId}`,
]

export type GetSpecialOfferInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSpecialOffer>>
>
export type GetSpecialOfferInfiniteQueryError = ErrorType<GetSpecialOfferDefault>

export const useGetSpecialOfferInfinite = <
  TData = Awaited<ReturnType<typeof getSpecialOffer>>,
  TError = ErrorType<GetSpecialOfferDefault>,
>(
  specialOfferId: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSpecialOffer>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSpecialOfferQueryKey(specialOfferId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpecialOffer>>> = ({ signal }) =>
    getSpecialOffer(specialOfferId, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof getSpecialOffer>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!specialOfferId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetSpecialOfferQueryResult = NonNullable<Awaited<ReturnType<typeof getSpecialOffer>>>
export type GetSpecialOfferQueryError = ErrorType<GetSpecialOfferDefault>

export const useGetSpecialOffer = <
  TData = Awaited<ReturnType<typeof getSpecialOffer>>,
  TError = ErrorType<GetSpecialOfferDefault>,
>(
  specialOfferId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSpecialOffer>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSpecialOfferQueryKey(specialOfferId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpecialOffer>>> = ({ signal }) =>
    getSpecialOffer(specialOfferId, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSpecialOffer>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!specialOfferId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const updateSpecialOffer = (
  updateSpecialOfferBody: UpdateSpecialOfferBody,
  specialOfferId?: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SpecialOffer>(
    {
      url: `/api/special-offers/${specialOfferId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateSpecialOfferBody,
    },
    options,
  )
}

export type UpdateSpecialOfferMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSpecialOffer>>
>
export type UpdateSpecialOfferMutationBody = UpdateSpecialOfferBody
export type UpdateSpecialOfferMutationError = ErrorType<UpdateSpecialOfferDefault>

export const useUpdateSpecialOffer = <
  TError = ErrorType<UpdateSpecialOfferDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSpecialOffer>>,
    TError,
    { data: UpdateSpecialOfferBody; specialOfferId?: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSpecialOffer>>,
    { data: UpdateSpecialOfferBody; specialOfferId?: string }
  > = (props) => {
    const { data, specialOfferId } = props ?? {}

    return updateSpecialOffer(data, specialOfferId, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof updateSpecialOffer>>,
    TError,
    { data: UpdateSpecialOfferBody; specialOfferId?: string },
    TContext
  >(mutationFn, mutationOptions)
}
