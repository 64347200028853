import { useMemo } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import { NextSeo } from 'next-seo'

import { useGetOneRandomSpecialOffer } from '@/hooks/generated-queries/special-offer/special-offer'
import { Button } from '@/components/ui/button'
import { SpecialOfferSection, SpecialOfferSectionProps } from '@/components/SpecialOfferSection'
import { Spinner } from '@/components/Spinner'

import homeSerramentiImg from '../../public/img/homepage-serramenti.jpeg'

export default function Home() {
  const { locale } = useRouter()
  const randomSpecialOfferQuery = useGetOneRandomSpecialOffer()
  const randomSpecialOffer = randomSpecialOfferQuery.data

  const specialOfferProps: SpecialOfferSectionProps | null = useMemo(() => {
    const localeUpper = (locale?.toUpperCase() ?? 'IT') as 'IT' | 'EN'

    return randomSpecialOffer
      ? {
          title: randomSpecialOffer[`TITOLO_${localeUpper}`],
          description: randomSpecialOffer[`DESCRIZIONE_${localeUpper}`],
          cta: {
            link: randomSpecialOffer[`CTA_LINK`],
            text: randomSpecialOffer[`CTA_TESTO_${localeUpper}`],
          },
        }
      : null
  }, [locale, randomSpecialOffer])

  const t = useTranslations('Home')

  return (
    <>
      <NextSeo title="Homepage" />
      {/* Hero */}
      <div className="flex flex-col border-b border-gray-200 lg:border-0 lg:min-h-[calc(100vh-65px)]">
        {/*<nav aria-label="Offers" className="order-last lg:order-first">
            <div className="mx-auto max-w-7xl lg:px-8">
              <ul
                role="list"
                className="grid grid-cols-1 divide-y divide-gray-200 lg:grid-cols-3 lg:divide-y-0 lg:divide-x"
              >
                {offers.map((offer) => (
                  <li key={offer.name} className="flex flex-col">
                    <a
                      href={offer.href}
                      className="relative flex flex-1 flex-col justify-center bg-white py-6 px-4 text-center focus:z-10"
                    >
                      <p className="text-sm text-gray-500">{offer.name}</p>
                      <p className="font-semibold text-gray-900">{offer.description}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>*/}

        <div className="relative flex flex-col lg:block flex-grow">
          <div aria-hidden="true" className="absolute hidden h-full w-1/2  lg:block" />
          <div className="relative  lg:bg-transparent">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:grid lg:grid-cols-2 lg:px-8">
              <div className="mx-auto max-w-2xl py-24 lg:max-w-none lg:py-64">
                <div className="lg:pr-16">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl">
                    Datek s.r.l.
                  </h1>
                  <p className="mt-4 text-xl text-gray-600">{t('description')}</p>
                  <div className="mt-6">
                    <Button size="xl" asChild>
                      <Link href="/products">{t('cta')}</Link>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-48 w-full sm:h-64 lg:absolute lg:top-0 lg:right-0 lg:h-full lg:w-1/2">
            <Image
              // src="https://tailwindui.com/img/ecommerce-images/home-page-02-hero-half-width.jpg"
              src={homeSerramentiImg}
              alt="Serramenti"
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>
      </div>

      {/* Trending products */}
      {/*
      <section aria-labelledby="trending-heading" className="bg-white">
        <div className="py-16 sm:py-24 lg:mx-auto lg:max-w-7xl lg:py-32 lg:px-8">
          <div className="flex items-center justify-between px-4 sm:px-6 lg:px-0">
            <h2 id="trending-heading" className="text-2xl font-bold tracking-tight text-gray-900">
              In evidenza
            </h2>
            <Link
              href="/products"
              className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
            >
              Tutti i articoli
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>

          <div className="relative mt-8">
            <div className="relative w-full overflow-x-auto">
              <ProductsGrid list={productsQuery?.data?.data} />
            </div>
          </div>

          <div className="mt-12 px-4 sm:hidden">
            <Link
              href="/products"
              className="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
            >
              Vai ai articoli
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </section>
*/}

      {/* Collections */}
      {/*
      <section aria-labelledby="collections-heading" className="bg-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <h2 id="collections-heading" className="text-2xl font-bold text-gray-900">
              Collections
            </h2>

            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {collections.map((collection) => (
                <div key={collection.name} className="group relative">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      src={collection.imageSrc}
                      alt={collection.imageAlt}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <a href={collection.href}>
                      <span className="absolute inset-0" />
                      {collection.name}
                    </a>
                  </h3>
                  <p className="text-base font-semibold text-gray-900">{collection.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
*/}

      {/* Sale and testimonials */}
      {/*<div className="relative overflow-hidden">
         Decorative background image and gradient
        <div aria-hidden="true" className="absolute inset-0">
          <div className="absolute inset-0 mx-auto max-w-7xl overflow-hidden xl:px-8">
            <Image
              src={homeSpecialOfferImg}
              alt="Special offer"
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="absolute inset-0 bg-white bg-opacity-75" />
          <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
        </div>

         Sale
        <section
          aria-labelledby="sale-heading"
          className="relative mx-auto flex max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8"
        >
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h2
              id="sale-heading"
              className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl"
            >
              Get 25% off during our one-time sale
            </h2>
            <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600">
              Most of our products are limited releases that won&apos;t come back. Get your favorite
              items while they&apos;re in stock.
            </p>
            <a
              href="#"
              className="mt-6 inline-block w-full rounded-md border border-transparent bg-gray-900 py-3 px-8 font-medium text-white hover:bg-gray-800 sm:w-auto"
            >
              Get access to our one-time sale
            </a>
          </div>
        </section>

         Testimonials
        <section
          aria-labelledby="testimonial-heading"
          className="relative mx-auto max-w-7xl py-24 px-4 sm:px-6 lg:py-32 lg:px-8"
        >
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h2
              id="testimonial-heading"
              className="text-2xl font-bold tracking-tight text-gray-900"
            >
              What are people saying?
            </h2>

            <div className="mt-16 space-y-16 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
              {testimonials.map((testimonial) => (
                <blockquote key={testimonial.id} className="sm:flex lg:block">
                  <svg
                    width={24}
                    height={18}
                    viewBox="0 0 24 18"
                    aria-hidden="true"
                    className="flex-shrink-0 text-gray-300"
                  >
                    <path
                      d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z"
                      fill="currentColor"
                    />
                  </svg>
                  <div className="mt-8 sm:mt-0 sm:ml-6 lg:mt-10 lg:ml-0">
                    <p className="text-lg text-gray-600">{testimonial.quote}</p>
                    <cite className="mt-4 block font-semibold not-italic text-gray-900">
                      {testimonial.attribution}
                    </cite>
                  </div>
                </blockquote>
              ))}
            </div>
          </div>
        </section>
      </div>*/}

      <Spinner spinning={randomSpecialOfferQuery.isInitialLoading}>
        {randomSpecialOffer && <SpecialOfferSection {...specialOfferProps} />}
      </Spinner>
    </>
  )
}

export { getStaticProps } from '@/lib/getStaticProps'
