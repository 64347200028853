import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { Button } from '@/components/ui/button'

import homeSpecialOfferImg from '../../public/img/homepage-special-offer-bg.jpeg'

export interface SpecialOfferSectionProps {
  title?: string
  description?: string
  cta?: {
    text?: string
    link?: string
  }
}

export const SpecialOfferSection: React.FC<SpecialOfferSectionProps> = ({
  title,
  description,
  cta,
}) => {
  return (
    <div className=" flex items-center justify-center py-40">
      <div className="relative overflow-hidden flex-grow">
        {/* Decorative background image and gradient */}
        <div aria-hidden="true" className="absolute inset-0">
          <div className="absolute inset-0 mx-auto max-w-7xl overflow-hidden xl:px-8">
            <Image
              src={homeSpecialOfferImg}
              alt="Special Offer"
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="absolute inset-0 bg-white bg-opacity-75" />
          <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
        </div>

        {/* Callout */}
        <section
          aria-labelledby="sale-heading"
          className="relative mx-auto flex max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8"
        >
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h2
              id="sale-heading"
              className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl whitespace-pre-line	"
            >
              {title}
            </h2>
            <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600 whitespace-pre-line">
              {description}
            </p>
            {cta?.link && (
              <div className="mt-6">
                <Button size="xl" asChild>
                  <Link href={cta?.link}>{cta?.text}</Link>
                </Button>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  )
}
